import methods from '@/enums/methods';
import Thoth from '../base/thoth';

class NegativeClientService extends Thoth {
    post(file) {
        const formData = new FormData();
        formData.append('clients', file);

        return this.fetch({
            url: '/negativeClient',
            data: formData,
            method: methods.POST,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
}

export default new NegativeClientService();
