<template>
  <b-row v-if="renderPage">
    <b-container>
      <b-overlay
        :show="showLoading"
        :opacity="1"
        variant="light"
        rounded="sm"
      >
        <template v-slot:overlay>
          <div class="d-flex align-items-center">
            <strong>Enviando Arquivo, Aguarde...</strong>
            <b-icon
              icon="stopwatch"
              font-scale="3"
              animation="cylon"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
            <b-spinner
              type="grow"
              variant="primary"
            />
            <b-spinner
              small
              type="grow"
              variant="primary"
            />
          </div>
        </template>
        <b-card>
          <validation-observer
            ref="uploadRede"
            #default="{ invalid }"
          >
            <b-form>
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Upload"
                  rules="required"
                >
                  <b-form-file
                    v-model="file"
                    placeholder="Arraste ou selecione um arquivo"
                    drop-placeholder="Arraste o arquivo aqui."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-button
                variant="primary"
                :disabled="invalid"
                class="btn-upload"
                @click="upload"
              >
                Enviar Arquivo
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-overlay>
    </b-container>
  </b-row>
</template>

<script>
import {
    BFormFile,
    BFormGroup,
    BButton,
    BRow,
    BCard,
    BContainer,
    BForm,
    BSpinner,
    BOverlay,
    BIcon,
} from 'bootstrap-vue';

import { ValidationProvider, ValidationObserver } from 'vee-validate';

import { required, email } from '@validations';
import NegativeClientService from '@/service/file/negative';

export default {
    components: {
        BFormFile,
        BFormGroup,
        BButton,
        BRow,
        BCard,
        BContainer,
        BForm,
        BSpinner,
        BOverlay,
        BIcon,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            file: null,
            showLoading: false,
            renderPage: true,
            required,
            email,
        };
    },
    beforeRouteLeave(to, from, next) {
        this.renderPage = false;
        next();
    },
    computed: {
        isDisabledFile() {
            return this.selectedFileOption.title === 'Selecione o Tipo de Arquivo';
        },
    },
    methods: {
        async upload() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente enviar o arquivo?');

            if (!isConfirmed) return;
            this.showLoading = true;

            const { status } = await NegativeClientService.post(this.file);

            if (status === 200) {
                this.success();
            } else {
                this.error();
            }
            this.showLoading = false;
        },
        success() {
            this.$swal({
                icon: 'success',
                html: '<span>O Arquivo foi Enviado com Sucesso </span> ',
            });
        },
        error() {
            this.$swal({
                icon: 'error',
                html: '<span>Não foi possível enviar o arquivo</span> ',
            });
        },
    },
};
</script>
    <style lang="scss">
      @import "@core/scss/vue/pages/upload-style.scss";
    </style>
